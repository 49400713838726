.nav {
  position: fixed;
  top: 24px;
  width: 100%;
  z-index: 1000;
  .menus {
    width: 67vw;
    height: 56px;
    margin: 0 auto;
    .left {
      float: left;
      .logo {
        position: relative;
        display: inline-block;
        vertical-align: top;
        z-index: 1000;
        img {
          display: inline-block;
          width: 56px;
          height: 56px;
        }
      }
      .title-wrapper {
        display: inline-flex;
        margin-left: 16px;
        flex-direction: column;
        vertical-align: top;
        justify-content: center;
        .title {
          font-size:24px;
          font-weight:400;
          color:rgba(55,56,58,1);
        }
        .desc {
          font-size:16px;
          font-weight:400;
          color:rgba(96,98,102,1);
        }
      }
    }
    .right {
      float: right;
      height: 56px;
      line-height: 56px;
      .nav-btn {
        display: inline-block;
        font-size:19px;
        font-weight:400;
        color:rgba(55,56,58,1);
        padding: 7px 41px;
        line-height: 26px;
        &.is-active {
          border-radius:24px;
          border:2px solid rgba(48,49,51,1);
        }
      }

    }
    .nav-right-img{
      float: right;
      display:none;
    }
    .right-tabs{
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin: 0;
      position: absolute;
      padding: 0;
      right: 55px;
      top: 4px;
      background-color: #ff8c73;
      border-radius: 5px;
      height: 0;
      width: 0;
      text-align: center;
      transition: all .2s cubic-bezier(0.08,0.82,0.17,1);
      .tab-item{
        font-size: .13rem;
        letter-spacing: 1px;
        font-weight:400;
        color: #fff;
        padding: 2px 4px;
        line-height: .3rem;
        transition: all .4s ease-in-out;
        &:not(:last-child){
          &:after{
            position: absolute;
            content: '';
            display: block;
            height: 1px;
            width: 90%;
            background: #ffcfb3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .nav {
    //top: .13rem;
    top: 0;
    padding-top: .14rem;
    .menus {
      width: 90vw;
      .left {
        .logo {
          img {
            width: .32rem;
            height: .32rem;
          }
        }
        .title-wrapper {
          margin-left: .08rem;
          height: .32rem;
          .title {
            font-size: .14rem;
          }
          .desc {
            font-size: .11rem;
          }
        }
      }
      .right {
        display: none;
      }
      .nav-right-img{
        display: block;
        .img{
          width: .3rem;
          height: .3rem;
        }
      }
    }
  }
}

@media (max-width: 1440px) and (min-width: 996px) {
  .nav {
    top: 16px;
    .menus {
      width: 72vw;
      height: 45px;
      .left {
        .logo {
          img {
            width: 45px;
            height: 45px;
          }
        }
        .title-wrapper {
          .title {
            font-size: 20px;
          }
          .desc {
            font-size: 14px;
          }
        }
      }
      .right {
        height: 45px;
        line-height: 45px;
        .nav-btn {
          font-size: 16px;
          padding: 5px 36px;
        }
      }
      .nav-right-img{
        display:none;
      }
    }
  }
}
