html,
body {
  position: relative;
  height: 100%;
}
body {
  background: #eee;
}

$mobile-title-font-size: .21rem;
$mobile-desc-font-size: .12rem;
$mobile-text-box-margin-left: .24rem;
$screen-font-size: 40px;
$small-screen-font-size: 35px;
$screen-desc-font-size: 24px;
$small-screen-desc-font-size: 20px;


.swiper-container {
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,1);

  .swiper-slide {
    background-color: rgba(255,255,255,1);
  }

  .swiper-slide-1 {
    position: relative;
    display: table;
    background: url('../image/index/pic-bg-1-1@2x.png')
      no-repeat
      scroll
      0 0 / 34vw 31.6vw
      content-box content-box,
      url('../image/index/pic-bg-1-2@2x.png')
      no-repeat
      scroll
      right bottom / 33.5vw 43.8vw
      content-box content-box;
    position: relative;

    .bg-3 {
      position: absolute;
      top: 6.3vw;
      right: 37.5vw;
      width: 15vw;
      padding-bottom: 15vw;
      background:rgba(255,94,0,1);
      border-radius: 50vw;
      opacity:0.3;
      animation: horizontal-1-5 4s linear 0s infinite alternate;
    }

    @keyframes horizontal-1-5 {
      0% {
        top: 6.3vw;
        right: 37.5vw;
      }
      100% {
        /* 运动最大距离为盒子长度减去球的长度 */
        top: 8.3vw;
        right: 40vw;
      }
    }

    .bg-4 {
      position: absolute;
      bottom: 117px;
      left: 25vw;
      width: 8.6vw;
      padding-bottom: 8.6vw;
      background:rgba(255,94,0,1);
      border-radius: 50vw;
      opacity:0.1;
      animation: horizontal-1-4 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-1-4 {
      0% {
        left: 25vw;
      }
      100% {
        left: 27vw;
      }
    }
    .content {
      display: table-cell;
      vertical-align: middle;
      .cell {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        .left {
          margin-right: 7vw;
          .left-img {
            width: 11.6vw;
          }
          .left-title {
            font-size:48px;
            font-weight:500;
            color:rgba(255,101,68,1);
            margin-top: 36px;
          }
          .left-desc {
            font-size:24px;
            font-weight:400;
            color:rgba(96,98,102,1);
            margin-top: 8px;
          }
          .qrcode-box {
            width: 5.8vw;
            height: 7.9vw;
            background:rgba(255,255,255,1);
            box-shadow:0px 2px 4px 0px rgba(0,0,0,0.1);
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            .qrcode {
              display: block;
              width: 64px;
              height: 64px;
              background:rgba(243,243,243,1);
              box-shadow:0px 2px 4px 0px rgba(0,0,0,0.1);
              margin: 0 auto;
              margin-top: 5px;
            }
            .desc {
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: center;
              .qrcode-desc {
                font-size:14px;
                font-weight:400;
                color:rgba(63,64,67,1);
                text-shadow:0px 2px 4px rgba(0,0,0,0.1);
                text-align: center;
              }
            }
          }
        }
        .right {
          width: 34vw;
          .icon-image {
            position: absolute;
            top: -20%;
            left: 70%;
            width: 33vw;
            height: 34.3vw;
            transform: translateX(-50%);
          }
          position: relative;
          .right-circle {
            width: 100%;
            padding-bottom: 100%;
            opacity:0.39;
            border:2px solid rgba(255,139,0,1);
            border-radius: 50%;
          }
          .right-img {
            position: absolute;
            top: -15%;
            left: 50%;
            width: 54%;
            transform: translateX(-50%);
            animation: bounce-up 2s linear infinite;
            .ico-play {
              position: absolute;
              cursor: pointer;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 23%;
            }
          }
        }
      }
    }
  }

  .swiper-slide-2 {
    position: relative;
    display: table;
    .bg-1 {
      position: absolute;
      top: 0;
      right: 0;
      width: 48.6vw;
      height: 35.5vw;
    }
    .bg-2 {
      position: absolute;
      top: 8.1vw;
      right: 17.9vw;
      width: 46.3vw;
      height: 26.7vw;
    }
    .bg-3 {
      position: absolute;
      left: 53px;
      bottom: 54px;
      width: 11.4vw;
      height: 11.4vw;
      background:rgba(250,93,101,1);
      border-radius: 50%;
      opacity:0.16;
      animation: horizontal-2-3 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-2-3 {
      0% {
        left: 53px;
      }
      100% {
        left: 83px;
      }
    }
    .bg-4 {
      position: absolute;
      right: 184px;
      bottom: 191px;
      width: 8.3vw;
      height: 8.3vw;
      background:rgba(250,93,101,1);
      border-radius: 50%;
      opacity:0.69;
      animation: horizontal-2-4 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-2-4 {
      0% {
        bottom: 191px;
      }
      100% {
        bottom: 231px;
      }
    }
    .content {
      display: table-cell;
      vertical-align: middle;
      position: relative;
      .cell {
        display: flex;
        justify-content: center;
        .left {
          .invite-friend {
            width: 35vw;
            height: 35vw;
          }
        }
        .right {
          display: flex;
          margin-left: 8.7vw;
          flex-direction: column;
          justify-content: center;
          .suprise {
            font-size: $screen-font-size;
            font-weight:500;
            color:rgba(255,88,96,1);
            margin-bottom: 24px;
          }
          .suprise-desc {
            font-size:$screen-desc-font-size;
            font-weight:400;
            color:rgba(96,98,102,1);
          }
        }
      }
    }
  }
  .swiper-slide-3 {
    position: relative;
    display: table;
    .bg-1 {
      position: absolute;
      top: 0;
      right: 0;
      width: 32.3vw;
      height: 32.3vw;
    }
    .bg-2 {
      position: absolute;
      top: 13.1vw;
      left: 14vw;
      width: 39.8vw;
      height: 27.8vw;
      animation: horizontal-3-2 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-3-2 {
      0% {
        top: 13.1vw;
        left: 14vw;
      }
      100% {
        top: 14.1vw;
        left: 12vw;
      }
    }
    .bg-3 {
      position: absolute;
      top: 6.5vw;
      left: 100px;
      width: 28.8vw;
      height: 29.4vw;
      animation: horizontal-3-3 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-3-3 {
      0% {
        top: 6.5vw;
        left: 100px;
      }
      100% {
        top: 5.5vw;
        left: 150px;
      }
    }
    .bg-4 {
      position: absolute;
      bottom: 95px;
      left: 37.7vw;
      width: 8.1vw;
      height: 8.1vw;
      background:rgba(255,139,0,1);
      opacity:0.29;
      border-radius: 50%;
      animation: horizontal-3-4 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-3-4 {
      0% {
        bottom: 95px;
      }
      100% {
        bottom: 125px;
      }
    }
    .content {
      display: table-cell;
      vertical-align: middle;
      position: relative;
      .cell {
        display: flex;
        justify-content: center;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 9.6vw;
          .business-title {
            font-size: $screen-font-size;
            font-weight:500;
            color:rgba(255,139,0,1);
            margin-bottom: 24px;
          }
          .business-desc {
            font-size: $screen-desc-font-size;
            font-weight:400;
            color:rgba(96,98,102,1);
          }
        }
        .right {
          .business {
            width: 35.1vw;
            height: 35.1vw;
          }
        }
      }
    }
  }
  .swiper-slide-4 {
    position: relative;
    display: table;
    .bg-1 {
      position: absolute;
      top: 0;
      right: 79px;
      width: 36.7vw;
      height: 34.6vw;
    }
    .bg-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30.2vw;
      height: 31.5vw;
    }
    .bg-3 {
      position: absolute;
      bottom: 88px;
      left: 90px;
      width: 8.9vw;
      height: 8.9vw;
      border-radius: 50%;
      background:rgba(255,176,0,1);
      opacity:0.2;
      animation: horizontal-4-3 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-4-3 {
      0% {
        bottom: 88px;
      }
      100% {
        bottom: 108px;
      }
    }
    .bg-4 {
      position: absolute;
      top: 226px;
      right: 37vw;
      width: 7.2vw;
      height: 7.2vw;
      border-radius: 50%;
      background:rgba(255,176,0,1);
      opacity:0.29;
      animation: horizontal-4-4 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-4-4 {
      0% {
        top: 226px;
        right: 37vw;
      }
      100% {
        top: 206px;
        right: 38vw;
      }
    }
    .content {
      display: table-cell;
      vertical-align: middle;
      .cell {
        display: flex;
        justify-content: center;
        .left {
          position: relative;
          margin-right: 7.6vw;
          .mother {
            position: relative;
            width: 36.8vw;
            height: 35.1vw;
          }
          .left-circle {
            position: absolute;
            left: -8.2vw;
            bottom: -3.5vw;
            width: 30.7vw;
            height: 30.7vw;
            border-radius: 50%;
            border:1px solid rgba(255,176,0,1);
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .mother-title {
            font-size: $screen-font-size;
            font-weight:500;
            color:rgba(255,176,0,1);
            margin-bottom: 24px;
          }
          .mother-desc {
            font-size: $screen-desc-font-size;
            font-weight:400;
            color:rgba(96,98,102,1);
          }
        }
      }
    }
  }
  .swiper-slide-5 {
    position: relative;
    .bg-1 {
      position: absolute;
      width: 28.9vw;
      height: 22.9vw;
      top: 111px;
      left: 18.3vw;
      animation: horizontal-5-1 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-5-1 {
      0% {
        top: 111px;
        left: 18.3vw;
      }
      100% {
        top: 131px;
        left: 17.3vw;
      }
    }
    .bg-2 {
      position: absolute;
      width: 28.9vw;
      height: 22.9vw;
      top: 111px;
      left: 8.3vw;
      animation: horizontal-5-2 4s ease-in-out 0s infinite alternate;
    }
    @keyframes horizontal-5-2 {
      0% {
        top: 111px;
        left: 8.3vw;
      }
      100% {
        top: 131px;
        left: 9.3vw;
      }
    }
    .bg-3 {
      position: absolute;
      right: 0;
      bottom: 153px;
      width: 26.8vw;
      height: 26.8vw;
      background: #fa5d65;
      opacity: 0.2;
      border-radius: 50%;
      transform: translateX(50%);
    }
    .bg-4 {
      position: absolute;
      right: 21.6vw;
      top: 233px;
      width: 7vw;
      height: 7vw;
      border-radius: 50%;
      background:rgba(250,93,101,1);
      opacity:0.2;
      animation: horizontal-5-4 4s ease-in-out 0s infinite alternate;
      .bg-5 {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 8.4vw;
        height: 8.4vw;
        border-radius: 50%;
        border:3px solid rgba(250,93,101,1);
      }
    }
    @keyframes horizontal-5-4 {
      0% {
        right: 21.6vw;
        top: 233px;
      }
      100% {
        right: 22.6vw;
        top: 213px;
      }
    }
    .content {
      position: relative;
      z-index: 1;
      display: flex;
      height: calc(100vh - 353px);
      align-items: center;
      justify-content: center;
      .wrapper {
        position: relative;
        width: 301px;
        height: 353px;
        .qrcode-footer {
          width: 301px;
          height: 353px;
        }
        .qrcode {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -47%, 0);
          width: 142px;
        }
        .lingqian {
          position: absolute;
          top: 295px;
          width: 301px;
          margin-left: 27px;
          font-size:20px;
          font-weight:400;
          color:rgba(55,56,58,1);
        }
        .title {
          position: absolute;
          top: 52px;
          right: 26px;
          font-size:25px;
          font-weight:500;
          color:rgba(55,56,58,1);
        }
      }
      .icon-wrapper {
        display: none;
      }
    }
    .footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 336.86px;
      background:rgba(255,255,255,1);
      opacity:0.69;
    }
    .footer-wrapper {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 336.86px;
      width: 67vw;
      display: flex;
      padding-top: 53px;
      .left-footer {
        display: flex;
        margin-right: 12.5vw;
        .logo {
          width: 56px;
          height: 56px;
        }
        .text-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 16px;
          height: 56px;
          .title {
            font-size:24px;
            font-weight:400;
            color:rgba(55,56,58,1);
          }
          .desc {
            font-size:16px;
            font-weight:400;
            color:rgba(96,98,102,1);
          }
        }
      }
      .right-footer {
        flex: 1;
        .compony-box {
          display: flex;
          justify-content: space-between;
          .title {
            display: block;
            font-size:18px;
            font-weight:400;
            color:rgba(55,56,58,1);
          }
          .desc {
            display: block;
            font-size:16px;
            font-weight:400;
            color:rgba(96,98,102,1);
            margin-top: 14px;
          }
        }
        .mobile-compony-box {
          display: none;
        }
        .friend-link {
          margin-top: 37px;
          .title {
            font-size:18px;
            font-weight:400;
            color:rgba(55,56,58,1);
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-right: -67px;
            margin-top: 10px;
            li {
              max-width: 120px;
              margin-right: 67px;
              margin-top: 8px;
              font-size:16px;
              font-weight:400;
              color:rgba(96,98,102,1);
              overflow:hidden; //超出的文本隐藏
              text-overflow:ellipsis; //溢出用省略号显示
              white-space:nowrap; //溢出不换行
              &:last-child {
                margin-right: 0;
              }
            }
            i {
              width: 67px;
              margin-right: 67px;
            }
          }
        }
      }
      .license {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 12px;
        font-size:12px;
        font-weight:300;
        color:rgba(96,98,102,1);
        width: fit-content;
        span {
          font-size:12px;
          font-weight:300;
          color:rgba(96,98,102,1);
        }
      }
    }
  }

  .btn-start {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  right: 1.25vw;
  top: 50%;
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0,-50%,0);
  z-index: 10;
  display: flex;
  flex-direction: column;

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 1.25vw;
    height: 1.25vw;
    margin-top: 1.25vw;
    background:rgba(255,255,255,1);
    opacity:0.5;
    &:focus {
      outline: none;
    }
    &.swiper-pagination-bullet-active {
      width: 1.25vw;
      height: 3.1vw;
      background:rgba(255,255,255,1);
      border-radius: 0.57vw;
      opacity: 1;
    }
  }
}

.videobox {
  display: none;
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 8;
  user-select: none;
  .playmp4 {
    --width: calc(100vw * 0.6);
    --height: var(--width) * (1080 / 1920);
    width: var(--width);
    height: var(--height);
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    video {
      width: var(--width);
      height: var(--height);
    }
    .close_off {
      width: 40px;
      height: 40px;
      position: absolute;
      border-radius: 100%;
      display: inline-block;
      top: -20px;
      right: -20px;
      background: #fff;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        width: 68%;
        height: 3px;
        top: 50%;
        left: 50%;
        display: block;
        background: #bebebe;
        transform: translate(-50%,-50%) rotate(-45deg);
      }
      &:after {
        content: "";
        position: absolute;
        width: 68%;
        height: 3px;
        top: 50%;
        left: 50%;
        display: block;
        background: #bebebe;
        transform: translate(-50%,-50%) rotate(45deg);
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .swiper-pagination {
    right: .07rem;
    top: unset;
    bottom: .24rem;
    .swiper-pagination-bullet {
      width: .12rem;
      height: .12rem;
      margin-top: .12rem;
      &.swiper-pagination-bullet-active {
        width: .12rem;
        height: .3rem;
        border-radius: .11rem;
      }
    }
  }
  .videobox {
    .playmp4 {
      width: 100%;
      height: auto;
      video {
        width: 100%;
        height: auto;
      }
      .close_off {
        top: unset;
        right: unset;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .content{
    z-index: 8;
  }
}
@media screen and (max-width: 996px) {
  .swiper-container {
    margin-top: .6rem;
    height: calc(100% - .6rem);
    .swiper-slide-1 {
      .bg-1 {
        width: 1.87rem;
        height: 3.09rem;
        left: -25%;
      }
      .bg-2 {
        width: 2.8rem;
        height: 3.75rem;
      }
      .bg-3 {
        width: 1.03rem;
        height: 1.03rem;
        left: .27rem;
        top: 1.64rem;
        animation: mobile-1-3 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-1-3 {
        0% {
          left: .27rem;
          top: 1.64rem;
        }
        100% {
          left: .32rem;
          top: 1.84rem;
        }
      }
      .bg-4 {
        width: .59rem;
        height: .59rem;
        right: .39rem;
        bottom: 1.3rem;
        left: unset;
        animation: mobile-1-4 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-1-4 {
        0% {
          right: .39rem;
          bottom: 1.3rem;
        }
        100% {
          right: .41rem;
          bottom: 1.7rem;
        }
      }
      .content {
        .cell {
          width: 3.25rem;
          margin: 0 auto;
          flex-direction: column;
          margin-top: -2rem;
          .left {
            margin-top: 0;
            margin-right: 0;
            .left-img {
              display: none;
            }
            .left-title {
              margin-top: 0;
              font-size: $mobile-title-font-size;
            }
            .left-desc {
              font-size: $mobile-desc-font-size;
            }
            .qrcode-box {
              display: none;
            }
          }
          .right {
            width: 2.76rem;
            margin: .55rem auto 0;
            .icon-image {
              top: -45%;
              left: 50%;
              width: 2.6rem;
              height: 2.7rem;
            }
            .right-circle {
            }
            .right-img {
              top: -5%;
            }
          }
        }
      }
      .btn-start {
        background:rgba(255,140,115,1);
        color:rgba(255,255,255,1);
      }
    }
    .swiper-slide-2 {
      .bg-2 {
        width: 3.67rem;
        height: 2.73rem;
        top: 50%;
        left: -0.05rem;
        right: unset;
        transform: translateY(-50%);
      }
      .bg-3 {
        width: 1.36rem;
        height: 1.36rem;
        right:  0;
        bottom: 0.19rem;
        left: unset;
        transform: translateX(50%);
        animation: mobile-2-3 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-2-3 {
        0% {
          right: 0;
          bottom: 0.19rem;
        }
        100% {
          right:  0;
          bottom: 0.19rem;
        }
      }
      .bg-4 {
        display: none;
      }
      .content {
        .cell {
          flex-direction: column-reverse;
          margin-top: -.5rem;
          .left {
            margin-top: .23rem;
            .invite-friend {
              width: 2.71rem;
              height: 2.73rem;
              display: block;
              margin: 0 auto;
            }
          }
          .right {
            margin-left: .24rem;
            .suprise {
              font-size: $mobile-title-font-size;
              margin-bottom: .06rem;
            }
            .suprise-desc {
              font-size: $mobile-desc-font-size;
              line-height: .2rem;
            }
          }
        }
      }
      .btn-start {
        background:rgba(250,93,101,1);
        color:rgba(255,255,255,1);
      }

    }

    .swiper-slide-3 {
      .bg-1 {
        width: 1.68rem;
        height: 1.68rem;
      }
      .bg-2 {
        width: 2.69rem;
        height: 2.15rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        animation: mobile-3-2 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-3-2 {
        0% {
          left: 0;
          top: 50%;
        }
        100% {
          left: 0;
          top: 50%;
        }
      }
      .bg-3 {
        width: 2.16rem;
        height: 2.04rem;
        bottom: 0;
        right: 0;
        top: unset;
        left: unset;
        animation: mobile-3-3 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-3-3 {
        0% {
          bottom: 0;
          right: 0;
        }
        100% {
          bottom: 0;
          right: 0;
        }
      }
      .bg-4 {
        width: .7rem;
        height: .7rem;
        left: .13rem;
        bottom: 1.03rem;
      }
      .content {
        .cell {
          flex-direction: column;
          margin-top: -.5rem;
          .left {
            margin-right: 0;
            margin-left: $mobile-text-box-margin-left;
            margin-bottom: .23rem;
            .business-title {
              font-size: $mobile-title-font-size;
              margin-bottom: .06rem;
            }
            .business-desc {
              font-size: $mobile-desc-font-size;
              line-height: .2rem;
            }
          }
          .right {
            .business {
              width: 2.71rem;
              height: 2.71rem;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
      .btn-start {
        background:rgba(255,139,0,1);
        color:rgba(255,255,255,1);
      }
    }

    .swiper-slide-4 {
      .bg-1 {
        width: 2.31rem;
        height: 2.95rem;
        top: -.64rem;
        right: -1rem;
        animation: unset;
      }
      .bg-2 {
        width: 1.71rem;
        height: 1.79rem;
        animation: unset;
      }
      .bg-3 {
        width: 1.02rem;
        height: 1.02rem;
        right: .07rem;
        top: 33%;
        left: unset;
        transform: translateY(-50%);
        animation: unset;
      }
      .bg-4 {
        width: .48rem;
        height: .48rem;
        left: .22rem;
        bottom: 1.36rem;
        top: unset;
        animation: unset;
      }
      .content {
        .cell {
          flex-direction: column-reverse;
          margin-top: -.5rem;
          .left {
            margin-top: .23rem;
            margin-right: unset;
            .left-circle {
              width: 2.62rem;
              height: 2.62rem;
              left: 3.8vw;
            }
            .mother {
              display: block;
              margin: 0 auto;
              width: 2.83rem;
              height: 2.83rem;
            }
          }
          .right {
            margin-left: $mobile-text-box-margin-left;
            .mother-title {
              font-size: $mobile-title-font-size;
              margin-bottom: .06rem;
            }
            .mother-desc {
              font-size: $mobile-desc-font-size;
              line-height: .2rem;
            }
          }
        }
      }
      .btn-start {
        background:rgba(255,176,0,1);
        color:rgba(255,255,255,1);
      }
    }
    .swiper-slide-5 {
      .content {
        position: absolute;
        bottom: 3.63rem;
        left: 0;
        width: 100%;
        display: unset;
        height: unset;
        .wrapper {
          display: none;
        }
        .icon-wrapper {
          display: block;
          img {
            display: block;
            width: 1.02rem;
            height: 1.02rem;
            margin: 0 auto;
          }
          .title {
            font-size: .2rem;
            font-weight:400;
            color:rgba(55,56,58,1);
            text-align: center;
            margin: .16rem 0 .08rem;
          }
          .desc {
            font-size: .14rem;
            font-weight:400;
            color:rgba(96,98,102,1);
            text-align: center;
          }
        }
      }
      .bg-1 {
        width: 2.36rem;
        height: 1.71rem;
        left: 0;
        top: 50%;
        animation: mobile-5-1 4s ease-in-out 0s infinite alternate;
      }
      @keyframes mobile-5-1 {
        0% {
          transform: translateY(-40%);
        }
        100% {
          transform: translateY(-50%);
        }
      }
      .bg-2 {
        width: 2.28rem;
        height: 2.24rem;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 0;
        animation: unset;
      }
      .bg-3 {
        width: 2.01rem;
        height: 2.01rem;
        top: 50%;
        animation: unset;
      }
      .bg-4 {
        width: .56rem;
        height: .56rem;
        top: .1rem;
        left: .1rem;
        animation: mobile-5-4 4s ease-in-out 0s infinite alternate;
        .bg-5 {
          width: .7rem;
          height: .7rem;
        }
      }
      @keyframes mobile-5-4 {
        0% {
          top: .1rem;
          left: .1rem;
        }
        100% {
          top: .2rem;
          left: .2rem;
        }
      }
      .btn-start {
        background:rgba(250,93,101,1);
        color:rgba(255,255,255,1);
        bottom: 2.81rem;
      }
      .footer {
        height: 2.5rem;
      }
      .footer-wrapper {
        height: 2.5rem;
        width: 100%;
        transform: unset;
        padding: .24rem;
        left: 0;
        .left-footer {
          display: none;
        }
        .right-footer {
          .compony-box {
            display: none;
          }
          .mobile-compony-box {
            display: block;
            span {
              font-size:14px;
              font-weight:400;
              color:rgba(55,56,58,1);
            }
            .business-coo {
              display: inline-block;
              margin-right: .66rem;
            }
            .welfare {
              display: inline-block;
            }
            img {
              width: .16rem;
              height: .16rem;
              display: inline-block;
              margin-left: .06rem;
            }
          }
          .friend-link {
            margin-top: .12rem;
            .title {
              font-size: .14rem;
            }
            ul {
              margin-right: -.4rem;
              margin-top: 0;
              li {
                max-width: .9rem;
                margin-right: .4rem;
                font-size: .12rem;
                margin-top: .16rem;
              }
              i {
                width: .5rem;
                margin-right: .4rem;
              }
            }
          }
        }
        .license {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            margin-top: 5px;
          }
        }
      }
    }

    .btn-start-wrapper{
      position: absolute;
      bottom: .32rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 11;
    }

    .btn-start {
      margin: 0 auto;
      display: block;
      width: 2.16rem;
      height: .48rem;
      border-radius: .24rem;
      font-size: .18rem;
      font-weight:500;
      letter-spacing: .01rem;
      text-align: center;
      line-height: .48rem;
      z-index: 100;
    }
  }
}

@media (max-width: 1440px) and (min-width: 996px) {
  .swiper-container {
    .swiper-slide-1 {
      .content {
        .cell {
          .left {
            .left-title {
              font-size: 40px;
            }
            .left-desc {
              font-size: 20px;
            }
            .qrcode-box {
              .desc {
                .qrcode-desc {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .swiper-slide-2 {
      .content {
        .cell {
          .right {
            .suprise {
              font-size: $small-screen-font-size;
            }
            .suprise-desc {
              font-size: $small-screen-desc-font-size;
            }
          }
        }
      }
    }
    .swiper-slide-3 {
      .content {
        .cell {
          .left {
            .business-title {
              font-size: $small-screen-font-size;
            }
            .business-desc {
              font-size: $small-screen-desc-font-size;
            }
          }
        }
      }
    }
    .swiper-slide-4 {
      .content {
        .cell {
          .right {
            .mother-title {
              font-size: $small-screen-font-size;
            }
            .mother-desc {
              font-size: $small-screen-desc-font-size;
            }
          }
        }
      }
    }
    .swiper-slide-5 {
      .content {
        height: calc(100vh - 260px);
        .wrapper {

        }
      }
      .footer {
        height: 260px;
      }
      .footer-wrapper {
        height: 260px;
        width: 72vw;
        .left-footer {
          margin-right: 10.5vw;
          .logo {
            width: 45px;
            height: 45px;
          }
          .text-wrapper {
            height: 45px;
            .title {
              font-size: 20px;
            }
            .desc {
              font-size: 14px;
            }
          }
        }
        .right-footer {
          .compony-box {
            .title {
              font-size: 16px;
            }
            .desc {
              font-size: 14px;
            }
          }
          .friend-link {
            margin-top: 25px;
            .title {
              font-size: 16px;
            }
            ul {
              li {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes bounce-up {
  25% {
    transform: translate3d(-50%, 20px, 0);
  }
  50%, 100% {
    transform: translate3d(-50%,0, 0);
  }
  75% {
    transform: translate3d(-50%, -20px, 0);
  }
}

