.mouse {
  width: 48px;
  height: 107px;
  position: absolute;
  bottom: 34.57px;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-in 2s infinite;
}
@media screen and (max-width: 996px) {
  .mouse {
    display: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
